.about {
    background-color: black;
}

.aboutTextPart {
    display: flex;
    align-items: center;
}

.downloadcv {
    color: white;
    background-color: rgb(211, 23, 111);
}

.downloadcv:hover {
background-color: transparent;
color: white;
border: 1px solid rgb(211, 23, 111);
}

@media screen and (max-width:769px) {
   .abtImg {
    width: 250px;
    margin-top: -100px;
    margin-left: 35px;
   }
   
}
