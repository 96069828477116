.home {
    background-image: url('https://media3.giphy.com/media/Y4ak9Ki2GZCbJxAnJD/giphy.gif?cid=ecf05e47bsmy69x4cbx7zhnjn6td8c44up7073csur5ztuj5&ep=v1_gifs_related&rid=giphy.gif&ct=g');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    height: 100vh;
    margin-top: 74.2px;
    
}

.img {
    margin-left: 300px;
    position: absolute;
    margin-top: 50px;
    height: 400px;
    width: 250px;
    border-radius: 80px;
    
    
}

.details {
    color:rgb(238, 118, 138) ;
    font-weight: bolder;
    font-size: 20px;
    
}

@media screen and (max-width: 768px){
    .home {
        height: auto;
        background-image: none;
    
    }
    .details {
        color: black;
    }
    .icons {
        margin-right: 120px;
    }
    .img {
        width: 80px;
        height: 80px;
        margin-left:270px;
        position: relative;
        margin-top: -115px;
        background-color: rgb(238, 118, 138);
        object-fit: contain;
    }

    .homeTextPart {
        text-align: center;
        padding-top: 80px;
    }
}

@media screen and (min-width: 769px){
    
    .homeTextPart {
        padding-left: 80px;
    }
}

